/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-has-content */
// https://github.com/grommet/grommet/blob/master/src/js/components/Layer/LayerContainer.js
// @flow
import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import type { Node } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import cn from 'classnames';
import { ICONS } from '../../constants';

import Button from '../Button';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Layer.css';

// import { ResponsiveContext } from '../../contexts/ResponsiveContext';
// import { OptionsContext } from '../../contexts/OptionsContext';
import { findVisibleParent } from '../../core/helpers';

const HiddenAnchor = () => (
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  <a
    style={{ width: 0, height: 0, overflow: 'hidden', position: 'absolute' }}
  />
);
type Props = {
  children: Node,
  full?: boolean,
  id?: number | string,
  modal?: boolean,
  onClickOutside?: () => void,
  onClose?: () => void,
  onEsc?: () => void,
  position?: string,
  // responsive?: boolean,
  target?: ?HTMLElement,
};
const defaultPortalContext = [];
const PortalContext = React.createContext(undefined);
const LayerContainer = forwardRef(
  (
    {
      children,
      full = false,
      id,
      // margin = 'none',
      modal = true,
      onClickOutside,
      onClose,
      onEsc,
      position = 'center',
      // responsive = true,
      target: layerTarget,
      ...rest
    }: Props,
    ref,
  ) => {
    // const size = useContext(ResponsiveContext);
    // layerOptions was created to preserve backwards compatibility but
    // should not be supported in v3
    // const { layer: layerOptions } = useContext(OptionsContext);
    useStyles(s);
    const anchorRef = useRef();
    const containerRef = useRef();
    const layerRef = useRef();
    const portalContext = useContext(PortalContext) || defaultPortalContext;
    const portalId = useMemo(() => portalContext.length, [portalContext]);
    const nextPortalContext = useMemo(
      () => [...portalContext, portalId],
      [portalContext, portalId],
    );

    const getPositioning = () => {
      if (full) {
        return [s.full];
      }
      return [s[position]];
    };

    useEffect(() => {
      if (position !== 'hidden') {
        const node = layerRef.current || containerRef.current || ref.current;
        if (node && node.scrollIntoView) node.scrollIntoView();
        // Once layer is open we make sure it has focus so that you
        // can start tabbing inside the layer. If the caller put focus
        // on an element already, we honor that. Otherwise, we put
        // the focus in the hidden anchor.
        let element = document.activeElement;
        while (element) {
          if (element === containerRef.current) {
            // already have focus inside the container
            break;
          }
          element = element.parentElement;
        }
        if (modal && !element && anchorRef.current) {
          anchorRef.current.focus();
        }
      }
    }, [modal, position, ref]);

    useEffect(() => {
      if (position !== 'hidden') {
        const node = layerRef.current || containerRef.current || ref.current;
        if (node && node.scrollIntoView) node.scrollIntoView();
      }
    }, [position, ref]);

    useEffect(() => {
      const onClickDocument = (event) => {
        // determine which portal id the target is in, if any
        let clickedPortalId = null;
        let node = event.target;
        while (clickedPortalId === null && node !== document && node !== null) {
          // check if user click occurred within the layer
          const attr = node.getAttribute('data-g-portal-id');
          if (attr !== null && attr !== '')
            clickedPortalId = parseInt(attr, 10);
          // loop upward through parents to see if clicked element is a child
          // of the Layer. if so, click was inside Layer
          else node = node.parentNode;
        }
        if (
          (clickedPortalId === null ||
            portalContext.indexOf(clickedPortalId) !== -1) &&
          node !== null
        ) {
          // if the click occurred outside of the Layer portal, call
          // the user's onClickOutside function
          onClickOutside(event);
        }
      };

      // if user provides an onClickOutside function, listen for mousedown event
      if (onClickOutside) {
        document.addEventListener('mousedown', onClickDocument);
      }

      if (layerTarget) {
        const updateBounds = () => {
          const windowWidth = window.innerWidth;
          const windowHeight = window.innerHeight;
          const target = findVisibleParent(layerTarget);

          // affects StyledLayer
          const layer = layerRef.current;

          if (layer && target) {
            // clear prior styling
            layer.style.left = '';
            layer.style.top = '';
            layer.style.bottom = '';
            layer.style.width = '';

            // get bounds
            // eslint-disable-next-line react/prop-types
            const targetRect = target.getBoundingClientRect();
            const layerRect = layer.getBoundingClientRect();

            // ensure that layer moves with the target
            layer.style.left = `${targetRect.left}px`;
            layer.style.right = `${windowWidth - targetRect.right}px`;
            layer.style.top = `${targetRect.top}px`;
            layer.style.bottom = `${windowHeight - targetRect.bottom}px`;
            layer.style.maxHeight = targetRect.height;
            layer.style.maxWidth = Math.min(layerRect.width, windowWidth);
          }
        };

        updateBounds();
        window.addEventListener('resize', updateBounds);
        window.addEventListener('scroll', updateBounds, true);

        return () => {
          window.removeEventListener('resize', updateBounds);
          window.removeEventListener('scroll', updateBounds, true);
          if (onClickOutside) {
            document.removeEventListener('mousedown', onClickDocument);
          }
        };
      }
      return () => {
        if (onClickOutside) {
          document.removeEventListener('mousedown', onClickDocument);
        }
      };
    }, [layerTarget, onClickOutside, portalContext, portalId]);
    let closeNode = null;
    if (onClose) {
      closeNode = (
        <div
          style={
            { position: 'absolute', top: 0, right: 0, zIndex: 1 } // eslint-disable-line
          }
        >
          <Button onClick={onClose} plain>
            <span style={{ padding: '10px', display: 'inline-block' }}>
              <svg
                version="1.1"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
                role="img"
                aria-label="close"
              >
                <path
                  fill="none"
                  stroke="#000"
                  strokeWidth="2"
                  d={ICONS.close}
                />
              </svg>
            </span>
          </Button>
        </div>
      );
    }
    let content = (
      <div
        // style={{ inset: margins[margin] }}
        className={cn(s.container, ...getPositioning())}
        // className={s.overlay}
        ref={ref || containerRef}
        // background={background}
        // layerOptions was created to preserve backwards compatibility but
        // should not be supported in v3. In v3, this should always be
        // ${id}__container
        id={id}
        // full={full}
        // margin={margin}
        // modal={modal}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        // position={position}
        // plain={plain}
        // responsive={responsive}
        // layerTarget={layerTarget}
        // portalId is used to determine if click occurred inside
        // or outside of the layer
        data-g-portal-id={portalId}
      >
        {/* eslint-disable max-len */}
        {/* eslint-disable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        <HiddenAnchor ref={anchorRef} tabIndex="-1" aria-hidden="true" />
        {/* eslint-enable jsx-a11y/anchor-is-valid, jsx-a11y/anchor-has-content */}
        {/* eslint-enable max-len */}
        {closeNode}
        {children}
      </div>
    );
    content = (
      <div
        className={s.slayer}
        ref={layerRef}
        id={id}
        // position={position}
        // responsive={responsive}
        // layerTarget={layerTarget}
        tabIndex="-1"
      >
        {modal && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className={s.overlay}
            // plain={plain}
            // responsive={responsive}
            onMouseDown={onClickOutside}
          />
        )}
        {content}
      </div>
    );

    content = (
      <PortalContext.Provider value={nextPortalContext}>
        {content}
      </PortalContext.Provider>
    );

    return content;
  },
);

export default LayerContainer;
