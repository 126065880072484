// cp from https://github.com/grommet/grommet/blob/master/src/js/utils/refs.js

import {
  useEffect,
  useRef,
  useLayoutEffect as vanillaUseLayoutEffect,
} from 'react';

/**
 * A substitute for React's useLayoutEffect, which does not generate warnings on
 * SSR. It is named useLayoutEffect so that all eslint rules applying to the
 * original useLayoutEffect would also apply to it.
 * This solution was suggested by Alex Reardon.
 * @see https://medium.com/@alexandereardon/uselayouteffect-and-ssr-192986cdcf7a
 * @see https://github.com/grommet/grommet/issues/4765
 */
export const useLayoutEffect =
  typeof window !== 'undefined' ? vanillaUseLayoutEffect : useEffect;

const updateRef = (ref, innerRef) => {
  if (!ref) return;
  if (typeof ref === 'function') {
    ref(innerRef.current);
  } else {
    // eslint-disable-next-line no-param-reassign
    ref.current = innerRef.current;
  }
};

// https://medium.com/the-non-traditional-developer/how-to-use-the-forwarded-ref-in-react-1fb108f4e6af
export const useForwardedRef = (ref) => {
  const innerRef = useRef(null);
  updateRef(ref, innerRef);
  useLayoutEffect(() => updateRef(ref, innerRef));
  useEffect(() => updateRef(ref, innerRef));
  return innerRef;
};
