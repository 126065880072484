// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* taken from : https://github.com/grommet/grommet/blob/master/src/scss/grommet-core/_objects.check-box.scss */\n\n.iIY2c {\n  margin-right: 12px;\n  white-space: nowrap;\n}\n\n.iIY2c:not(.UgxuJ) {\n  cursor: pointer;\n}\n\n.SJYUt {\n  opacity: 0;\n  position: absolute;\n\n  &:checked + .oiaLM {\n    border-color: var(--color-accent-3);\n\n    .faKeQ {\n      display: block;\n    }\n\n    + .UCQ8x {\n      color: black;\n    }\n  }\n}\n\n.oiaLM {\n  position: relative;\n  top: -1px;\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  margin-right: 12px;\n  vertical-align: middle;\n  background-color: inherit;\n  border: 2px solid var(--color-accent-3);\n  border-radius: 2px;\n}\n\n.faKeQ {\n  position: absolute;\n\n  /* top: -2px;\n  left: -2px; */\n\n  display: none;\n  width: 24px;\n  height: 24px;\n  stroke-width: 4px;\n  stroke: var(--color-accent-3);\n}\n\n.UCQ8x {\n  display: inline-block;\n  vertical-align: middle;\n  color: grey;\n  white-space: normal;\n  margin-right: 12px;\n  word-break: break-word;\n}\n\n.UgxuJ {\n  .oiaLM {\n    opacity: 0.5;\n  }\n}\n\n.GSJtF {\n  /* track */\n  .oiaLM {\n    width: 48px;\n    height: 24px;\n    border-radius: 24px;\n    background-color: #ebebeb;\n    border: none;\n    transition: background-color 0.3s;\n\n    /* knob */\n    &::after {\n      content: '';\n      display: block;\n      position: absolute;\n      top: -2px;\n      left: 0;\n      width: 28px;\n      height: 28px;\n      background-color: #fff;\n      border: 2px solid black;\n      border-radius: 24px;\n      transition: margin-left 0.3s;\n      box-sizing: border-box;\n    }\n  }\n\n  .SJYUt {\n    &:checked + .oiaLM {\n      background-color: var(--color-accent-3);\n\n      &::after {\n        content: '';\n        background-color: #fff;\n        border-color: var(--color-accent-3);\n        margin-left: 24px;\n      }\n\n      .faKeQ {\n        display: none;\n      }\n    }\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkBox": "iIY2c",
	"disabled": "UgxuJ",
	"input": "SJYUt",
	"control": "oiaLM",
	"check": "faKeQ",
	"label": "UCQ8x",
	"toggle": "GSJtF"
};
module.exports = ___CSS_LOADER_EXPORT___;
