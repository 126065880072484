// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes PbACk {\n  0% {\n    opacity: 0.5;\n    transform: scale(0.8);\n  }\n\n  100% {\n    opacity: 1;\n    transform: scale(1);\n  }\n}\n\n.Zrrls {\n  border-radius: 0;\n  position: fixed;\n  z-index: 20;\n  outline: none;\n  background-color: rgb(255, 255, 255);\n  color: rgb(68, 68, 68);\n  opacity: 0;\n  transform-origin: left top;\n  animation: 0.1s ease 0.01s 1 normal forwards running PbACk;\n  animation-delay: 0.01s;\n  display: flex;\n  box-sizing: border-box;\n  max-width: 100%;\n  min-width: 0;\n  min-height: 0;\n  flex-direction: column;\n  overflow: auto;\n  box-shadow: rgb(0 0 0 / 20%) 0 2px 4px;\n\n  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {\n    display: flex;\n    align-items: stretch;\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"drop": "Zrrls",
	"dropFrames": "PbACk"
};
module.exports = ___CSS_LOADER_EXPORT___;
