// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".z8Hmw {\n  text-align: left;\n}\n\n.z8Hmw > *:hover {\n  background-color: var(--color-accent-3-shade);\n}\n\n.K4chQ {\n  background-color: hsla(0, 0%, 97.3%, 0.95);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "z8Hmw",
	"drop": "K4chQ"
};
module.exports = ___CSS_LOADER_EXPORT___;
