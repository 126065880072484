// @flow
// https://github.com/grommet/grommet/blob/master/src/js/components/Layer/Layer.js
import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLayoutEffect } from '../../core/refs';
import { getNewContainer } from '../../core/helpers';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Layer.css';

import LayerContainer from './LayerContainer';

// import { animationDuration } from './StyledLayer';
// import { LayerPropTypes } from './propTypes';

type Props = {
  targetChildPosition?: {},
  animate?: boolean | string,
  animation?: boolean | string,
};
const ContainerTargetContext = React.createContext(
  typeof document === 'object' ? document.body : undefined,
);
const animationDuration = 200;

const Layer = forwardRef((props: Props, ref) => {
  const { targetChildPosition, animate, animation } = props;
  const [originalFocusedElement, setOriginalFocusedElement] = useState();
  useEffect(() => setOriginalFocusedElement(document.activeElement), []);
  const [layerContainer, setLayerContainer] = useState();
  const containerTarget = useContext(ContainerTargetContext);
  useEffect(
    () =>
      setLayerContainer(getNewContainer(containerTarget, targetChildPosition)),
    [containerTarget, targetChildPosition],
  );

  // just a few things to clean up when the Layer is unmounted
  useLayoutEffect(
    () => () => {
      if (originalFocusedElement) {
        if (originalFocusedElement.focus) {
          // wait for the fixed positioning to come back to normal
          // see layer styling for reference
          setTimeout(() => originalFocusedElement.focus(), 0);
        } else if (
          originalFocusedElement.parentNode &&
          originalFocusedElement.parentNode.focus
        ) {
          // required for IE11 and Edge
          originalFocusedElement.parentNode.focus();
        }
      }
      if (layerContainer) {
        const activeAnimation = animation !== undefined ? animation : animate;
        if (activeAnimation !== false) {
          // undefined uses 'slide' as the default
          // animate out and remove later
          const layerClone = layerContainer.cloneNode(true);
          layerClone.id = 'layerClone';
          containerTarget.appendChild(layerClone);
          const clonedContainer = layerClone.querySelector(
            `[class*="${s.container}"]`,
          );
          if (clonedContainer && clonedContainer.style) {
            clonedContainer.style.animationDirection = 'reverse';
          }
          setTimeout(() => {
            // we add the id and query here so the unit tests work
            const clone = document.getElementById('layerClone');
            if (clone) {
              containerTarget.removeChild(clone);
              layerContainer.remove();
            }
          }, animationDuration);
        } else {
          containerTarget.removeChild(layerContainer);
        }
      }
    },
    [
      // animate,
      // animation,
      containerTarget,
      layerContainer,
      originalFocusedElement,
    ],
  );

  return layerContainer
    ? // eslint-disable-next-line react/jsx-props-no-spreading
      createPortal(<LayerContainer ref={ref} {...props} />, layerContainer)
    : null;
});

Layer.displayName = 'Layer';
Layer.defaultProps = {
  animate: undefined,
  animation: undefined,
  targetChildPosition: undefined,
};

export default Layer;
