// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YNvMR {\n  position: relative;\n  max-width: 100%;\n  width: 30em;\n\n  fieldset {\n    min-width: 0;\n    border: none;\n    margin: 0;\n    margin-bottom: 2rem;\n    margin-top: 1rem;\n\n    &:first-child {\n      margin-top: 0;\n    }\n\n    &:last-child {\n      margin-bottom: 0;\n    }\n\n    > legend {\n      font-size: 1.5rem;\n      font-weight: 600;\n      margin-bottom: 1rem;\n    }\n\n    /*  > *:not(.form-field) + .form-field {\n      margin-top: 1rem;\n    }\n\n    > .#.form-field + *:not(.form-field):not(.form-fields) {\n      margin-top: 2rem;\n    }\n\n    > .form-fields {\n      display: flex;\n      flex-direction: row;\n\n      .form-field {\n        margin-bottom: -1px;\n      }\n\n      > .button {\n        flex: 0 0 auto;\n      }\n    } */\n  }\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "YNvMR"
};
module.exports = ___CSS_LOADER_EXPORT___;
