// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".B8BDf {\n  position: fixed;\n  left: 0;\n  right: 0;\n  z-index: 100;\n  stroke: #000;\n}\n\n.uoJHJ {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  min-height: 72px;\n  /*background-color: var(--color-brand);*/\n  padding: 0 1rem;\n}\n\n.I7X7S {\n  background-color: var(--color-accent-2);\n  color: #fff;\n  stroke: #fff;\n}\n\n.Ok6Z5 {\n  flex: 1;\n  /*padding-right: 1rem;*/\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "B8BDf",
	"toast": "uoJHJ",
	"alert": "I7X7S",
	"contents": "Ok6Z5"
};
module.exports = ___CSS_LOADER_EXPORT___;
