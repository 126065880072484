// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vrOBZ {\n  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05), 0 20px 48px rgba(0, 0, 0, 0.05),\n    0 1px 4px rgba(0, 0, 0, 0.1);\n  max-width: 25em;\n  margin-top: 3rem;\n  padding: 1em;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "vrOBZ"
};
module.exports = ___CSS_LOADER_EXPORT___;
