// @flow
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import Layer from '../Layer';

// eslint-disable-next-line css-modules/no-unused-class
import s from './Toast.css';

type Props = {
  position?: string,
  alert?: boolean,
  children: Node,
  duration?: number,
  onClose?: () => void,
};

const Toast = ({ position, alert, children, duration, onClose }: Props) => {
  const classes = cn(s.toast, {
    [s.alert]: alert,
  });
  const [open, setOpen] = useState(true);

  useEffect(() => {
    let timer;
    if (duration) {
      timer = setTimeout(() => {
        setOpen(undefined);
      }, duration);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  return open ? (
    <Layer modal={false} position={position} onClose={onClose}>
      <div className={classes}>
        <div className={s.contents}>{children}</div>
      </div>
    </Layer>
  ) : null;
};

Toast.defaultProps = {
  position: undefined,
  alert: undefined,
  duration: undefined,
  onClose: undefined,
};
export default withStyles(s)(Toast);
