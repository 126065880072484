// @flow
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import useStyles from 'isomorphic-style-loader/useStyles';
import type { ElementRef, Node } from 'react';
import Box from '../Box/Box';
import Button from '../Button';
import DropButton from '../DropButton';
import type { AlignType } from '../Drop';

import s from './Menu.css';

const DEFAULT_ALIGN = {
  top: 'top',
  left: 'left',
};

type Props = {
  a11yTitle?: string,
  'aria-label'?: string,
  children?: Node,
  disabled?: boolean,
  dropAlign?: AlignType,
  dropProps?: { align: AlignType },
  dropTarget?: ElementRef<typeof HTMLElement>,
  icon?: HTMLElement | Node,
  items: [
    | {
        label: string,
        icon?: HTMLElement,
        reverse?: boolean,
        onClick?: () => void,
      }
    | Node,
  ],
  passTrough?: boolean,
  label?: string,
  open?: boolean,
  plain?: boolean,
};
const Menu = forwardRef((props: Props, ref) => {
  const {
    a11yTitle,
    'aria-label': ariaLabel,
    children,
    disabled,
    dropAlign,
    dropProps,
    dropTarget,
    // justifyContent,
    icon,
    items,
    label,
    open,
    plain,
    passTrough,
    ...rest
  } = props;
  useStyles(s);
  const align = (dropProps && dropProps.align) || dropAlign || DEFAULT_ALIGN;

  const controlButtonIndex = useMemo(() => {
    if (align.top === 'top') return -1;
    if (align.bottom === 'bottom') return items.length;
    return undefined;
  }, [align, items]);

  const [alignControlMirror, setAlignControlMirror] = useState();
  const initialAlignTop = alignControlMirror === align.top;

  const buttonRefs = {};
  const constants = useMemo(
    () => ({
      none: 'none',
      tab: 9,
      // Menu control button included on top of menu items
      controlTop: align.top === 'top' || undefined,
      // Menu control button included on the bottom of menu items
      controlBottom: align.bottom === 'bottom' || undefined,
      controlButtonIndex,
    }),
    [align, controlButtonIndex],
  );

  const [activeItemIndex, setActiveItemIndex] = useState(constants.none);
  const [isOpen, setOpen] = useState(open || false);

  const onDropClose = useCallback(() => {
    setActiveItemIndex(constants.none);
    setOpen(false);
  }, [constants.none]);

  const onDropOpen = useCallback(() => {
    setOpen(true);
  }, []);

  let content;
  const buttonProps = { plain, icon, label };
  if (children) {
    content = children;
  } else {
    content = undefined;
  }

  const controlMirror = (
    <div
      style={{ justifyContent: align.right ? 'end' : 'start', display: 'flex' }}
    >
      <Button
        plain={plain}
        icon={icon}
        ref={(r) => {
          // make it accessible at the end of all menu items
          buttonRefs[items.length] = r;
        }}
        a11yTitle={ariaLabel}
        active={activeItemIndex === controlButtonIndex}
        focusIndicator={false}
        hoverIndicator="background"
        onClick={onDropClose}
        onFocus={() => setActiveItemIndex(controlButtonIndex)}
        // On first tab into menu, the control button should not
        // be able to receive tab focus because the focus should
        // go to the first menu item instead.
        tabIndex={activeItemIndex === constants.none ? '-1' : undefined}
      >
        {typeof content === 'function'
          ? () => content({ ...props, drop: true })
          : content}
      </Button>
    </div>
  );

  return (
    <DropButton
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonProps}
      label={!icon && label}
      plain={plain}
      disabled={disabled}
      dropAlign={align}
      dropTarget={dropTarget}
      open={isOpen}
      onOpen={onDropOpen}
      onClose={onDropClose}
      onAlign={setAlignControlMirror}
      dropContent={
        <Box column className={s.drop}>
          {alignControlMirror === 'top' && align.top === 'top'
            ? controlMirror
            : undefined}
          <Box fill column>
            {items.map((item, index) => {
              if (passTrough) {
                return item;
              }
              const child = (
                <Box row>
                  {item.reverse && item.label}
                  {item.icon}
                  {!item.reverse && item.label}
                </Box>
              );

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className={s.content} key={index}>
                  <Button
                    ref={(r) => {
                      buttonRefs[index] = r;
                    }}
                    fill
                    plain
                    active={activeItemIndex === index}
                    onClick={(...args) => {
                      if (item.onClick) {
                        item.onClick(...args);
                      }
                      if (item.close !== false) {
                        onDropClose();
                      }
                    }}
                  >
                    {child}
                  </Button>
                </div>
              );
            })}
          </Box>
          {!initialAlignTop &&
          (alignControlMirror === 'bottom' || align.bottom === 'bottom')
            ? controlMirror
            : undefined}
        </Box>
      }
    >
      {content}
    </DropButton>
  );
});
Menu.defaultProps = {
  a11yTitle: undefined,
  'aria-label': undefined,
  children: undefined,
  disabled: undefined,
  dropAlign: undefined,
  dropProps: undefined,
  dropTarget: undefined,
  icon: undefined,
  passTrough: undefined,
  label: undefined,
  open: undefined,
  plain: undefined,
};
export default Menu;
