// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ey4gj {\n  font-weight: 300;\n  cursor: inherit;\n  font-size: 1.1875rem;\n  line-height: 1.26316;\n}\n\n.dLYtR {\n  margin-top: 24px;\n  margin-bottom: 24px;\n}\n\n.hKjZ0 {\n  font-size: 2.5rem;\n  color: var(--color-accent-5);\n  text-align: center;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "ey4gj",
	"margin": "dLYtR",
	"big": "hKjZ0"
};
module.exports = ___CSS_LOADER_EXPORT___;
