import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import s from './Box.css'; // eslint-disable-line css-modules/no-unused-class

const Box = forwardRef(
  (
    {
      tag = 'div',
      column,
      children,
      onClick,
      pad,
      className,
      containerClassName,
      align,
      justify,
      wrap,
      flex,
      between,
      padding,
      fill,
    },
    ref,
  ) => {
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    const Component = tag;
    useStyles(s);
    return (
      <Component
        ref={ref}
        className={cn(
          s.root,
          className || null,
          containerClassName || null,
          column ? s.column : null,
          onClick ? s.clickable : null,
          pad ? s.pad : null,
          align ? s.align : null,
          justify ? s.justify : null,
          wrap ? s.wrap : null,
          flex ? s.flex : null,
          between ? s.between : null,
          fill ? s.fill : null,
          {
            [s[padding]]: padding,
          },
        )}
        onClick={onClick}
      >
        {children}
      </Component>
    );
  },
);

Box.propTypes = {
  tag: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  column: PropTypes.bool,
  className: PropTypes.string,
  pad: PropTypes.bool,
  align: PropTypes.bool,
  containerClassName: PropTypes.string,
  justify: PropTypes.bool,
  wrap: PropTypes.bool,
  flex: PropTypes.bool,
  between: PropTypes.bool,
  padding: PropTypes.string,
  fill: PropTypes.bool,
};
Box.defaultProps = {
  tag: 'div',
  children: null,
  onClick: null,
  column: false,
  className: null,
  pad: false,
  align: false,
  containerClassName: null,
  justify: false,
  wrap: false,
  flex: false,
  between: false,
  padding: null,
  fill: null,
};

export default Box;
