// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".prrG_ {\n  position: relative;\n  padding: 6px 24px;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  margin-bottom: -1px;\n  background-color: #fff;\n  color: #333;\n  opacity: 1;\n  overflow: visible;\n  transition: all 0.4s, padding-top 0.3s 0.1s, padding-bottom 0.3s 0.1s;\n}\n\n.ae9OV {\n  display: block;\n  margin-left: -24px;\n  margin-right: -24px;\n}\n\n.ae9OV > input {\n  height: 36px;\n  padding: 0 24px;\n  display: block;\n  width: 100%;\n  border: none;\n  border-radius: 0;\n  font-size: 16px;\n  font-size: 1rem;\n  line-height: 1.5;\n  padding-left: 22px;\n  box-sizing: border-box;\n  outline: none;\n  background-color: transparent;\n  color: inherit;\n  margin: 0;\n}\n\n.ae9OV > label {\n  display: block;\n  font-size: 16px;\n  font-size: 1rem;\n  line-height: 1.5;\n  margin: 6px 24px;\n}\n\n.ae9OV > textarea {\n  vertical-align: top;\n  resize: vertical;\n  padding: 0 24px;\n  display: block;\n  width: 100%;\n  border: none;\n  border-radius: 0;\n  font-size: 16px;\n  font-size: 1rem;\n  line-height: 1.5;\n  padding-left: 22px;\n  box-sizing: border-box;\n  outline: none;\n  background-color: transparent;\n  color: inherit;\n  margin: 0;\n}\n\n.ae9OV input {\n  border: 0;\n}\n\n.PitPS {\n  display: block;\n  float: right;\n  color: var(--color-red);\n  line-height: 24px;\n}\n\n.Pd68c {\n  z-index: 1;\n  border-color: var(--color-red);\n}\n\n.nN9M3 {\n  display: block;\n}\n\n.IDOw8 {\n  z-index: 1;\n  border-color: var(--color-accent-1);\n}\n\n.s1ft2 {\n  overflow: visible;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"field": "prrG_",
	"contents": "ae9OV",
	"field_error": "PitPS",
	"error": "Pd68c",
	"help": "nN9M3",
	"focus": "IDOw8",
	"overflow": "s1ft2"
};
module.exports = ___CSS_LOADER_EXPORT___;
