// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zTcuc {\n  display: flex;\n  background-position: center center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  flex-direction: row;\n}\n\n.zTcuc:not(.XdQIj) > *:not(:last-child) {\n  margin-right: 24px;\n\n  @media only screen and (max-width: 30em) {\n    margin-right: 12px;\n  }\n}\n\n.zTcuc.XdQIj > *:not(:last-child) {\n  margin-bottom: 24px;\n}\n\n.XdQIj {\n  flex-direction: column;\n}\n\n.dlIxe {\n  justify-content: space-between;\n}\n\n.p6OJ_ {\n  cursor: pointer;\n  outline: none;\n}\n\n.sfEyU {\n  align-items: center;\n}\n\n.qIDPd {\n  justify-content: center;\n}\n\n.AmHFT {\n  flex-wrap: wrap;\n}\n\n.ZwKtT {\n  flex: 1 0;\n}\n\n.zTcuc legend {\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n  font-weight: 600;\n}\n\n.iMmAq {\n  padding: 3em;\n\n  @media only screen and (max-width: 30em) {\n    padding: 1em;\n  }\n}\n\n.O5PRR {\n  padding: 1rem;\n}\n\n.gkiDQ {\n  width: 100%;\n}\n\n.w5Feu {\n  padding-left: 24px;\n  padding-right: 24px;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "zTcuc",
	"column": "XdQIj",
	"between": "dlIxe",
	"clickable": "p6OJ_",
	"align": "sfEyU",
	"justify": "qIDPd",
	"wrap": "AmHFT",
	"flex": "ZwKtT",
	"medium": "iMmAq",
	"small": "O5PRR",
	"fill": "gkiDQ",
	"pad": "w5Feu"
};
module.exports = ___CSS_LOADER_EXPORT___;
