// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].rules[2]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].rules[3]!../variables.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".br1fC {\n  background-color: transparent;\n  border: 2px solid var(--color-brand);\n  border-radius: 5px;\n  color: #333;\n  font-size: 1.1875rem;\n  line-height: 24px;\n  font-weight: 600;\n  cursor: pointer;\n  text-align: center;\n  outline: none;\n  min-width: 120px;\n  max-width: 384px;\n  padding: 6px 22px;\n  transition: 0.1s ease-in-out;\n}\n\n.U9LSp {\n  text-decoration: none;\n}\n\n.br1fC:not(.X5gVl) {\n  flex: 0 0 auto;\n}\n\n.u4HHB {\n  color: #fff;\n  background-color: var(--color-brand);\n}\n\n.u4HHB :not(.NM57S):hover {\n  box-shadow: 0 0 0 2px var(--color-brand);\n}\n\n.G4Rlx {\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n}\n\n.LrckF {\n  border: 2px solid transparent;\n  padding: 0;\n  width: auto;\n  height: auto;\n  min-width: 0;\n  max-width: none;\n  text-align: inherit;\n  font-weight: inherit;\n  border-radius: 0;\n  line-height: inherit;\n\n  .U9LSp {\n    margin: 0 12px;\n  }\n\n  .G4Rlx {\n    /*  padding: 12px; */\n\n    + .U9LSp {\n      margin-left: 0;\n    }\n  }\n}\n\n.X5gVl {\n  max-width: none;\n  width: 100%;\n  flex-grow: 1;\n}\n\n.GzMsv {\n  border: 2px solid var(--color-accent-1);\n}\n\n.NM57S {\n  opacity: 0.3;\n  cursor: default;\n}\n\n.br1fC:not(.LrckF) {\n  .G4Rlx + .U9LSp {\n    margin-left: 12px;\n  }\n}\n\n.GzMsv:not(.NM57S):not(.LrckF):hover {\n  box-shadow: 0 0 0 2px var(--color-accent-1);\n}\n/* stylelint-disable */\n\n.br1fC:not(.LrckF) {\n  .G4Rlx {\n    + .U9LSp {\n      margin-left: 12px;\n    }\n  }\n}\n\n.br1fC:not(.LrckF) {\n  .U9LSp {\n    + .G4Rlx {\n      margin-left: 12px;\n    }\n  }\n}\n/* stylelint-enable */\n\n.br1fC:not(.NM57S):not(.GzMsv):not(.LrckF):hover {\n  box-shadow: 0 0 0 2px var(--color-brand);\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "br1fC",
	"label": "U9LSp",
	"fill": "X5gVl",
	"primary": "u4HHB",
	"disabled": "NM57S",
	"icon": "G4Rlx",
	"plain": "LrckF",
	"accent": "GzMsv"
};
module.exports = ___CSS_LOADER_EXPORT___;
