// Taken from https://github.com/grommet/grommet/blob/master/src/js/utils/Drop.js
// github.com/grommet/grommet/blob/master/src/js/components/Drop/Drop.js
/* eslint-disable no-mixed-operators */
// @flow

import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import type { ElementRef } from 'react';
import DropContainer from './DropContainer';
import { getNewContainer } from '../../core/helpers';

const ContainerTargetContext = React.createContext(
  typeof document === 'object' ? document.body : undefined,
);
export type AlignType = {
  top?: 'top' | 'bottom',
  bottom?: 'top' | 'bottom',
  left?: 'left' | 'right',
  right?: 'left' | 'right',
};
type Props = {
  target: ElementRef<typeof HTMLElement>,
};

const Drop = forwardRef(({ target: dropTarget, ...rest }: Props, ref) => {
  const [origFocusedElement, setOrigFocusedElement] = useState();
  useEffect(() => setOrigFocusedElement(document.activeElement), []);
  const [dropContainer, setDropContainer] = useState();
  const containerTarget = useContext(ContainerTargetContext);
  useEffect(
    () => setDropContainer(getNewContainer(containerTarget)),
    [containerTarget],
  );

  // cleanup
  useEffect(
    () => () => {
      if (dropContainer) {
        containerTarget.removeChild(dropContainer);
      }
    },
    [containerTarget, dropContainer, origFocusedElement],
  );
  return dropContainer
    ? createPortal(
        // eslint-disable-next-line react/jsx-props-no-spreading
        <DropContainer ref={ref} dropTarget={dropTarget} {...rest} />,
        dropContainer,
      )
    : null;
});

export default Drop;
